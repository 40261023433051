<template>
    <div>
        <Quote/>
        <GoBack next="O'quvchi qo'shish"/>

        <div class="sent mt-0 py-0">
            <div class="divp5">
                <h6 class="text-center">Modulga o'quvchi qo'shish</h6>
                <div class="row justify-content-center">
                    <div class="col-12 col-sm-8 col-md-6 mb-3">
                        <b-form-select
                            name="bar"
                            class="form-control"
                            v-model="forCourse"
                            id="exampleInputId2"
                            required
                            multiple
                            :select-size="15"
                        >
                            <option value="null" disabled selected hidden>Modulni tanlang...</option>

                            <optgroup label="Aktiv modullar" class="text-primary">
                                <option
                                    v-for="course of getTakeCourses"
                                    :key="course.id"
                                    :label="course.module.name + ' kursining ' + course.name + ' (' + course.startMonth.name + ')' + ' moduli'"
                                    :value="course"
                                    v-show="course.isActive === true"
                                ></option>
                            </optgroup>
                            <optgroup label="Arxiv modullar" class="text-danger">
                                <option
                                    v-for="course of getTakeCourses"
                                    :key="course.id"
                                    :label="course.module.name + ' kursining ' + course.name + ' (' + course.startMonth.name + ')' + ' moduli'"
                                    :value="course"
                                    v-show="course.isActive === false"
                                ></option>
                            </optgroup>
                        </b-form-select>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-12 col-sm-8 col-md-6 mb-4 mx-5">
                        <div class="d-flex">
                            <input-component
                                model-name="text"
                                type="text"
                                id="category"
                                :placeholder="'Id, ism, familya, telegram, telefon raqam'"
                                :model-form="search"
                                @keyup.enter="findStudent"
                            />
                        </div>
                    </div>

                    <div class="col-12 col-sm-8 col-md-6 mt-3" v-if="getFoundUser.length > 0">
                        <div v-for="(user, index) of getFoundUser" :key="user.id" class="mb-4">
                            <div @click="getFoundUserId(user.id, index)" style="cursor: pointer" :class="isActive">
                                <p>№: <strong>{{ index + 1 }}</strong></p>
                                <p>ID: <strong>{{ user.id }}</strong></p>
                                <p>Ism: <strong>{{ user.givenName }}</strong></p>
                                <p>Familiya: <strong>{{ user.familyName }}</strong></p>
                                <p>Tel raqam: <strong>{{ user.phoneNumber }}</strong></p>
                                <p>Telegram: <strong>{{ user.telegramNumber }}</strong></p>
                                <p>Email: <strong>{{ user.email }}</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center d-block d-sm-flex">
                    <div class="col-12 col-sm-8 col-md-6">
                        <div class="row d-flex justify-content-between">
                            <div @click="findStudent" class="col-12 col-md-5 mb-3 mb-md-0">
                                <button-component
                                    :class="'btn form-control me-3'"
                                    id="search"
                                >
                                    Qidirish
                                    <img src="../assets/search.svg">
                                </button-component>
                            </div>
                            <div @click="addStudent" class="col-12 col-md-5">
                                <button-component>
                                    Qo'shish
                                    <img src="../assets/add.svg">
                                </button-component>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="row div6 justify-content-center mt-3"
                    v-if="foundStudent.givenName !== '' || foundStudent.familyName !== '' ||
                    foundStudent.telegramNumber !== '' || foundStudent.phoneNumber !== ''"
                >
                    <div class="col-12 col-sm-8 col-md-6">
                        <span> {{ this.foundStudent.givenName }}</span>
                        <span> {{ this.foundStudent.familyName }}</span>
                        <p><strong> tel: </strong> {{ this.foundStudent.phoneNumber }}</p>
                        <p><strong> telegram: </strong> {{ this.foundStudent.telegramNumber }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <b-modal ref="modal-student-select" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                    <h3>O'quvchini tanlang</h3>
                </div>
                <b-button
                    class="mt-3 float-end"
                    style="width: 100px"
                    variant="btn btn-primary"
                    size="md"
                    @click="hideErrorStudentModal"
                >
                    OK
                </b-button>
            </b-modal>
        </div>

        <div>
            <b-modal ref="modal-student-course" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                    <h3>Kursni tanlang</h3>
                </div>
                <b-button
                    class="mt-3 float-end"
                    style="width: 100px"
                    variant="btn btn-primary"
                    size="md"
                    @click="hideErrorStudentModal"
                >
                    OK
                </b-button>
            </b-modal>
        </div>

        <div>
            <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                    <h3>Bu kurs allaqachon to'lgan</h3>
                </div>
                <div class="d-flex justify-content-between">
                    <b-button
                        class="mt-3"
                        style="width: 100px"
                        variant="btn btn-primary"
                        size="md"
                        @click="hideErrorStudentModal"
                    >
                        OK
                    </b-button>
                    <b-button
                        class="mt-3"
                        style="width: 150px;padding: 3px;border-radius: 9px"
                        variant="btn btn-danger"
                        size="md"
                        @click="finishAddStudentToCourse"
                    >
                        Baribir qo'shish
                    </b-button>
                </div>
            </b-modal>
        </div>

        <div>
            <b-modal ref="modal-success-member" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                    <h3>Bu o'quvchi ushbu kursda mavjud</h3>
                </div>
                <b-button
                    class="mt-3 float-end"
                    style="width: 100px"
                    variant="btn btn-primary"
                    size="md"
                    @click="hideErrorStudentModal"
                >
                    OK
                </b-button>
            </b-modal>
        </div>

        <div>
            <b-modal ref="modal-student" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                    <h3>O'quvchi kursga muvaffaqiyatli qo'shildi</h3>
                </div>
                <b-button
                    class="mt-3 float-end"
                    style="width: 100px"
                    variant="btn btn-primary"
                    size="md"
                    @click="hideErrorStudentModal; $router.push('/course-students/' + addStudentForm.course)"
                >
                    OK
                </b-button>
            </b-modal>
        </div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </div>
</template>

<script>
import Quote from "@/components/Quote";
import {mapActions, mapGetters} from "vuex";
import GoBack from "@/components/GoBack";
import ButtonComponent from "@/components/ButtonComponent.vue";
import InputComponent from "@/components/InputComponent.vue";

export default {
    name: "AddStudent",
    components: {ButtonComponent, Quote, GoBack, InputComponent},
    data() {
        return {
            addStudentForm: {
                course: null,
                user: null
            },
            forCourse: [],
            students: {
                text: []
            },
            search: {
                text: ''
            },
            foundStudent: {
                givenName: '',
                familyName: '',
                phoneNumber: '',
                telegramNumber: ''
            },
            show: true,
            foundUser: {
                users: []
            },
            usersList: []
        }
    },
    methods: {
        ...mapActions([
            'addStudentToCourse',
            'fetchTakeCourse',
            'fetchUser',
            'searchUserByMatchingText',
            "fetchOneUser",
        ]),
        hideErrorStudentModal() {
            this.$refs['modal-student-select'].hide()
            this.$refs['modal-student'].hide()
            this.$refs['modal-success'].hide()
            this.$refs['modal-student-course'].hide()
            this.$refs['modal-success-member'].hide()
        },
        async addStudent() {
            console.log('addStudent ishladi')

            if (this.addStudentForm.user === null) {
                this.$refs['modal-student-select'].show()
                return
            }

            for (let course of this.forCourse) {
                this.addStudentForm.course = course

                if (this.addStudentForm.course === null) {
                    this.$refs['modal-student-course'].show()
                    return
                }

                if (this.addStudentForm.course.members >= this.addStudentForm.course.countOfStudents) {
                    this.$refs['modal-success'].show()
                } else {
                    this.finishAddStudentToCourse()
                }
            }
        },
        finishAddStudentToCourse() {
            this.addStudentForm.course = this.addStudentForm.course.id
            this.addStudentToCourse(this.addStudentForm)
                .then((response) => {
                    console.log(response)
                    this.$refs['modal-student'].show()
                })
                .catch((reason) => {
                    console.log(reason)

                    if (reason === 'This student is already member of this course') {
                        this.$refs['modal-success-member'].show()
                    }
                })
        },
        getFoundUserId(userId, index) {
            this.foundStudent.givenName = this.getFoundUser[index].givenName
            this.foundStudent.familyName = this.getFoundUser[index].familyName
            this.foundStudent.phoneNumber = this.getFoundUser[index].phoneNumber
            this.foundStudent.telegramNumber = this.getFoundUser[index].telegramNumber
            this.addStudentForm.user = userId
            console.log(userId)
        },
        findStudent() {
            this.students.text = this.search.text.split(' ')
            this.searchUserByMatchingText(this.students)
        }
    },
    beforeMount() {
        if (this.$route.params.id) {
            this.fetchOneUser(this.$route.params.id)
                .then(() => {
                    this.search.text = this.getOneUser.givenName
                    this.foundUser.users.push(this.getOneUser)
                    this.getFoundUserId(this.getOneUser.id, 0)
                })
        }
    },
    computed: {
        ...mapGetters([
            'getTakeCourses',
            'getFoundUser',
            "getOneUser",
            'getActiveCourses'
        ]),
        isActive() {
            return this.isSelected ? 'background-color: #80007F;' : 'background-color: #FFFFFF;'
        }
    },
    mounted() {
        this.show = true
        this.fetchUser()
        this.fetchTakeCourse()
        this.show = false
    }
}
</script>

<style scoped>

* {
    padding: 0;
    margin: 0;
}

.bi-search {
    transform: rotate(90deg);
}

svg {
    margin-bottom: 5px;
    margin-left: 7px;
}

#search {
    color: #FFFFFF;
    background-color: #03ab18;
}

#search:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #03ab18 !important;
}

.row {
    margin: auto;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../public/img/select.svg");
    background-repeat: no-repeat;
    background-size: 25px;
}

select::-ms-expand {
    display: none !important;
}

.Tech a {
    font-size: 18px;
    line-height: 20px;
    text-align: right;
    text-decoration-line: underline;
    padding-top: 20px;
    float: right;
}

.sent {
    padding-top: 110px;
    padding-bottom: 110px;
    padding-left: 5%;
    padding-right: 5%;
}

.senter h5 {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 700;
    font-size: 24.9761px;
    line-height: 28px;
    padding-left: 12.5%;
    padding-right: 10%;
    padding-top: 85px;
    padding-bottom: 15px;
}

.senter h6 {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 700;
    font-size: 24.9761px;
    line-height: 28px;
    margin: 30px 0;
}

.sent h6 {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 700;
    font-size: 24.9761px;
    line-height: 28px;
    margin: 30px 0;
}

.divp6 li {
    margin-left: 10%;
    margin-right: 10%;
    padding-bottom: 15px;
}

.form-control {
    border-radius: 10px;
    border: solid #80007F 1px;
    font-family: Comfortaa, cursive;
    padding-left: 10px;
    outline: none !important;
}

.form-control:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.mb-3 .form-control {
    border-radius: 10px;
    border: solid #80007F 1px;
    font-family: Comfortaa, cursive;
}

.select .btn-primary {
    border-radius: 10px;
    background-color: #80007F;
    font-family: Comfortaa, cursive;
    font-size: 16px;
}

.btn-primary {
    border-radius: 10px;
    background-color: #80007F;
    font-family: Comfortaa, cursive;
    font-size: 16px;
    width: 100%;
}
</style>
